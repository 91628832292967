<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as CNT_ORG_TYPES } from '../../../organization-contact/store'
import { ROUTES as SUBJECT_CONTACT_ROUTES } from './router'
import { TYPES as SUBJECT_CONTACT_TYPES } from './store'

import ContactDetailMixin from '../../../components/contact/mixins/ContactDetailMixin'

export default {
  name: 'subjectcontactDetail',
  mixins: [ContactDetailMixin],
  data () {
    return {
      SUBJECT_CONTACT_ROUTES,
      title: 'Subject contact',
      titleList: 'Subject contacts',
      showSubscribeToCoursesButton: false
    }
  },
  computed: {
    ...mapGetters({
      ...SUBJECT_CONTACT_TYPES.GENERIC.organization.subjectcontact.DETAIL.GETTERS
    }),
    showDocumentsButton () {
      return false
    }
  },
  methods: {
    ...mapActions({
      ...SUBJECT_CONTACT_TYPES.GENERIC.organization.subjectcontact.DETAIL.ACTIONS,
      getContactList: SUBJECT_CONTACT_TYPES.GENERIC.organization.subjectcontact.LIST.ACTIONS.getList,
      createOrganizationContact: CNT_ORG_TYPES.GENERIC.organization.organizationcontact.DETAIL.ACTIONS.create
    }),
    goToOrganizationDetail () {},
    goToList () {
      this.getContactList().then(() => {
        this.$router.push({ name: SUBJECT_CONTACT_ROUTES.SUBJECT_CONTACT_LIST })
      })
    }
  }
}
</script>
