<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as SUBJECT_CONTACT_TYPES } from './store'
import { hasPerm } from '@/components/PermissionHelper'

import ContactTableMixin from '../../../components/contact/mixins/ContactTableMixin'

export default {
  name: 'SubjectContactTable',
  mixins: [ContactTableMixin],
  data () {
    return {
      showSubscribeToCoursesButton: false,
      fields: [
        {
          key: 'checks',
          label: this.$t('Checks')
        },
        {
          key: 'status',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'status'
        },
        {
          key: 'id',
          label: this.$t('Code'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'id'
        },
        {
          key: 'last_name',
          label: this.$t('Last name'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'last_name'
        },
        {
          key: 'first_name',
          label: this.$t('First name'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'first_name'
        },
        {
          key: 'ssn',
          label: this.$t('SSN'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'ssn'
        },
        {
          key: 'birth_date',
          label: this.$t('Birth date'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          type: 'date',
          sortable: true,
          value: 'birth_date'
        },
        {
          key: 'birth_place',
          label: this.$t('Birth place'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'birth_place'
        },
        {
          key: 'primary_email',
          label: this.$t('Email'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: false,
          value: 'primary_email'
        },
        {
          key: 'primary_phone',
          label: this.$t('Phone'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: false,
          value: 'primary_phone'
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: ['status', 'id', 'last_name', 'first_name', 'ssn', 'birth_date', 'birth_place']
    }
  },
  computed: {
    ...mapGetters({
      selectAll: SUBJECT_CONTACT_TYPES.GENERIC.organization.subjectcontact.LIST.GETTERS.selectAll,
      selectedList: SUBJECT_CONTACT_TYPES.GENERIC.organization.subjectcontact.LIST.GETTERS.selectedList
    }),
    showDetailButton () {
      return hasPerm('organization_subjectcontact_retrieve')
    }
  },
  methods: {
    ...mapMutations({
      setList: SUBJECT_CONTACT_TYPES.GENERIC.organization.subjectcontact.LIST.MUTATIONS.setList,
      setSelectedList:
        SUBJECT_CONTACT_TYPES.GENERIC.organization.subjectcontact.LIST.MUTATIONS.setSelectedList,
      setSelectAll: SUBJECT_CONTACT_TYPES.GENERIC.organization.subjectcontact.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...SUBJECT_CONTACT_TYPES.GENERIC.organization.subjectcontact.LIST.ACTIONS
    })

  }
}
</script>
