<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as SUBJECT_CONTACT_TYPES } from './store'
import { ROUTES as SUBJECT_CONTACT_ROUTES } from './router'

import SubjectContactTable from './SubjectContactTable'
import SubjectContactCreate from './SubjectContactCreate'
import SubjectContactDetail from './SubjectContactDetail'
import SubjectContactCommonFilters from './SubjectContactCommonFilters'
import ContactListMixin from '../../../components/contact/mixins/ContactListMixin'

export default {
  name: 'SubjectContactList',
  props: ['orgId'],
  mixins: [ContactListMixin],
  components: {
    'component-table': SubjectContactTable,
    'common-filters': SubjectContactCommonFilters,
    'component-edit': SubjectContactCreate,
    'component-detail': SubjectContactDetail
  },
  data () {
    return {
      SUBJECT_CONTACT_ROUTES,
      title: this.$t('Subject contacts'),
      rolePerm: ['organization_subjectcontact_list'],
      actionEnablePermission: ['organization_subjectcontact_enable'],
      actionDisablePermission: ['organization_subjectcontact_disable']
    }
  },
  computed: {
    ...mapGetters({
      ...SUBJECT_CONTACT_TYPES.GENERIC.organization.subjectcontact.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.SUBJECT_CONTACT_ROUTES.SUBJECT_CONTACT_LIST
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: SUBJECT_CONTACT_TYPES.GENERIC.organization.subjectcontact.LIST.MUTATIONS.setSelectedList,
      setSelectAll: SUBJECT_CONTACT_TYPES.GENERIC.organization.subjectcontact.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...SUBJECT_CONTACT_TYPES.GENERIC.organization.subjectcontact.LIST.ACTIONS
    }),
    init () {
      this.$set(this, 'showCommonFilters', true)
      this.$set(this, 'showHeader', true)
      this.searchFields = [
        { key: 'filter__last_name__icontains', placeholder: this.$t('Last name'), type: 'text', col: 4 },
        { key: 'filter__first_name__icontains', placeholder: this.$t('First name'), type: 'text', col: 4 },
        { key: 'filter__ssn__icontains', placeholder: this.$t('Ssn'), type: 'text', col: 4 },
        { key: 'filter__birth_date', placeholder: this.$t('Birth date'), type: 'date', col: 4 },
        { key: 'filter__birth_place__icontains', placeholder: this.$t('Birth place'), type: 'text', col: 4 }
      ]
    },
    openDetailForm (cntId) {
      this.$emit('show-contact-detail', true)
      this.$router.push({
        name: SUBJECT_CONTACT_ROUTES.SUBJECT_CONTACT_DETAIL, params: { cntId: cntId }
      })
    }
  }
}
</script>
